const api = {
    getCommentList: 'https://ky3d.com/addons/yb_guanwang/core/index.php/api/index/getCommentList',
    getOpenid: 'https://ky3d.com/addons/yb_guanwang/core/index.php/api/index/getOpenid',
    sendComment:'/wapapi/addons/yb_guanwang/core/index.php/api/index/sendComment',
    getExhibitionTitle:'/wapapi/addons/yb_guanwang/core/index.php/api/index/getExhibitionTitle',
    recordData:'/wapapi/addons/yb_guanwang/core/index.php/api/index/recordData'
}

  
import axios from 'axios'

export function getCommentList(parameter) {
    return axios.get(api.getCommentList, {
        params: parameter
    })
}

export function getOpenid(parameter) {
    return axios.get(api.getOpenid, {
        params: parameter
    })
}
  
export function sendComment(parameter) {
    return axios.post(api.sendComment, parameter)
}

export function getExhibitionTitle(parameter) {
    return axios.post(api.getExhibitionTitle, parameter)
}

export function recordData(parameter) {
    return axios.post(api.recordData, parameter)
}