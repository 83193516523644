<template>
    <div>
        <div class="bottom-thumbnail">
            <div class="bottom-img-cover" >
                <div @click="locateScence(item)" v-for="(item,k) in list" v-bind:key="k">
                    <img :src="`/template/${tem_id}/${item}.png`"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {getQueryVariable} from "@/js/tool";

export default {
    name: '',
    data(){
        return{
            tem_id:getQueryVariable("tem_id"),
            listData:{
                37:[1,2,3,4,5],
                44:[1,2,3,4,5,6],
                43:[1,2,3,4,5,6,7]
            },
            list:[]
        }
    },
    created(){
        this.list = this.listData[this.tem_id]
    },
    methods:{
        locateScence(type){
            window.game.cameraControl.changeScence(type);
        }
    }
}
</script>
<!-- 两种样式 768px为边界线-->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 768px) {
    .bottom-thumbnail{
      height: 70px;
      display:inline-block;
    }
    .bottom-thumbnail .bottom-img-cover {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-x: scroll;
    }
    .bottom-thumbnail .bottom-img-cover > div{
      width: 57px;
      height: 57px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid rgb(255,255,255);
      margin-left: 3px;
      cursor: pointer;
    }
    .bottom-thumbnail .bottom-img-cover > div > img{
      width: 57px;
      height: 57px;
      object-fit: cover;
    }
}
@media screen and (min-width: 768px) {
    .bottom-thumbnail{
      height: 100px;
      display:inline-block;
      width: 100%;
    }
    .bottom-thumbnail .bottom-img-cover {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-x: scroll;
    }
    .bottom-thumbnail .bottom-img-cover > div{
      width: 80px;
      height: 80px;
      border: 3px solid rgb(255,255,255);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      cursor: pointer;
    }
    .bottom-thumbnail .bottom-img-cover > div > img{
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
}
</style>
