import { 
    Scene,
    Engine,
    Color3,
    MeshBuilder,
    StandardMaterial,
    Texture,
    InstancedMesh,
    PBRMaterial
} from "@babylonjs/core";

export class SceneParams {
    constructor(scene:Scene,tem_id:Number) {
        this.scene = scene
        this.tem_id = tem_id
    }
    //初始化场景函数
    initParams(){
        //通用处理
        //用MeshBuilder创建一个ground 地板  
        const ground = MeshBuilder.CreateGround("", { width: 1000, height: 1000 },this.scene)
        ground.checkCollisions = true
        ground.position.y = -0.15
        ground.visibility = 0
        this.scene.fogMode = 3
        this.scene.fogDensity = 0.001
        this.scene.fogStart = 0
        this.scene.fogEnd = 600
        this.scene.fogColor = Color3.FromHexString("#414424")
        this.scene.environmentIntensity = 2
        this.scene.imageProcessingConfiguration.vignetteEnabled = true
        this.scene.imageProcessingConfiguration.vignetteWeight = 1.5
        this.scene.imageProcessingConfiguration.vignetteStretch = 1
        this.scene.imageProcessingConfiguration.vignetteCameraFov = 0.6
        let skyBox = MeshBuilder.CreateSphere('sky', {}, this.scene)
        skyBox.checkCollisions = false
        skyBox.scaling.x = -1
        skyBox.scaling.scaleInPlace(200)
        skyBox.applyFog = false
        let skyMat = new StandardMaterial('skyMat', this.scene)
        let skyTexture = new Texture('sky.jpg', this.scene, false, false)
        skyMat.backFaceCulling = false;
        skyMat.diffuseTexture = skyTexture
        skyMat.emissiveColor = Color3.White()
        skyBox.material = skyMat
        skyBox.isPickable = false
        // console.log(skyBox);
        
        this.scene.createDefaultLight()
        // this.scene.createDefaultEnvironment({ createSkybox: false })

        //特殊处理 如果有一样的参数会直接覆盖
        let tem_id = Number(this.tem_id)
        switch (tem_id) {
            case 37:
                ground.position.y = 0
                break;
            case 38:
                break;
            case 39:
                break;
            case 40:
                break;
            case 41:
                break;
            case 42:
                break;
            case 43:
                break;
            case 44:
                this.scene.environmentIntensity = 1;
                break;
            case 47:
                this.scene.fogMode = 2
                this.scene.fogColor = Color3.FromHexString('#E585ff')
                this.scene.fogDensity = 0.007
                break;
            case 51:
                ground.position.y = -0.1
                break;
            case 54:
                ground.position.y = 0.1
                break;
        }
    }
}
