import {Observable} from "@babylonjs/core";

export class LoadingManage {
    onDisplayObs: Observable = new Observable()
    onHideObs: Observable = new Observable()
    onLoadingProgress: Observable<number> = new Observable()

    constructor() {
        this.progress = 1
        this.isloading = false
    }

    displayLoadingUI() {
        this.onDisplayObs.notifyObservers()
        this.progress = 0
        this.isloading = true
    }

    hideLoadingUI() {
        this.onHideObs.notifyObservers()
        this.progress = 100
        this.isloading = false
    }

    sendProgress = (progress) => {
        this.isloading = true
        this.progress = progress
        this.onLoadingProgress.notifyObservers(progress)
    }

}

