export const cameraTransforms = {
    44:[
        {//开始界面 0
            targetPositionx:17.27,
            targetPositiony:1.86,
            targetPositionz:14.5,
            targetRotationx:0,
            targetRotationy:Math.PI,
            targetRotationz:0,
            stayTime:6

        },
        {//序厅 1-3     
            targetPositionx:17.27,
            targetPositiony:1.86,
            targetPositionz:14.5,
            targetRotationx:(0)*Math.PI,
            targetRotationy:(180/180)*Math.PI,
            targetRotationz:0,
            stayTime:0.5
        },
        {//2 播放（序厅企业介绍）
            targetPositionx:20,
            targetPositiony:1.86,
            targetPositionz:5.29,
            targetRotationx:(-2.3/180)*Math.PI,
            targetRotationy:(134.65/180)*Math.PI,
            targetRotationz:0,
            stayTime:23
        },
        {//3
            targetPositionx:20.8,
            targetPositiony:1.86,
            targetPositionz:9.17,
            targetRotationx:(-0.71/180)*Math.PI,
            targetRotationy:(90/180)*Math.PI,
            targetRotationz:0,
            stayTime:10
        },
        {//4
            targetPositionx:20.8,
            targetPositiony:1.86,
            targetPositionz:9.17,
            targetRotationx:(-0.71/180)*Math.PI,
            targetRotationy:(198/180)*Math.PI,
            targetRotationz:0,
            stayTime:0
        },
        {//5
            targetPositionx:15.62,
            targetPositiony:1.86,
            targetPositionz:-5.29,
            targetRotationx:(-0.71/180)*Math.PI,
            targetRotationy:(198/180)*Math.PI,
            targetRotationz:0,
            stayTime:0
        },
        {//6 换装区 播放换装区
            targetPositionx:15.62,
            targetPositiony:1.86,
            targetPositionz:-5.29,
            targetRotationx:(-0.71/180)*Math.PI,
            targetRotationy:(77.33/180)*Math.PI,
            targetRotationz:0,
            stayTime:35
        },
        {//7
            targetPositionx:15.62,
            targetPositiony:1.86,
            targetPositionz:-5.29,
            targetRotationx:(-0.71/180)*Math.PI,
            targetRotationy:(180/180)*Math.PI,
            targetRotationz:0,
            stayTime:0.2
        },
        {//8 原石区 播放原石区
            targetPositionx:17.98,
            targetPositiony:1.86,
            targetPositionz:-8.41,
            targetRotationx:(-4/180)*Math.PI,
            targetRotationy:(231/180)*Math.PI,
            targetRotationz:0,
            stayTime:35
        },
        {//9荣誉墙 播放荣誉墙 视频
            targetPositionx:0,
            targetPositiony:1.86,
            targetPositionz:-10.7,
            targetRotationx:(0)*Math.PI,
            targetRotationy:(274.32/180)*Math.PI,
            targetRotationz:0,
            stayTime:0
        },
        {//10 
            targetPositionx:-12.49,
            targetPositiony:1.86,
            targetPositionz:-0.31,
            targetRotationx:(-2.27/180)*Math.PI,
            targetRotationy:(362.05/180)*Math.PI,
            targetRotationz:0,
            stayTime:10
        },
        {//11
            targetPositionx:-12.49,
            targetPositiony:1.86,
            targetPositionz:-0.31,
            targetRotationx:(2.5/180)*Math.PI,
            targetRotationy:(460/180)*Math.PI,
            targetRotationz:0,
            stayTime:0
        },
        {//12
            targetPositionx:-4.75,
            targetPositiony:1.86,
            targetPositionz:-2.06,
            targetRotationx:(2.5/180)*Math.PI,
            targetRotationy:(460/180)*Math.PI,
            targetRotationz:0,
            stayTime:0
        },
        {//13 体验区 播放体验区
            targetPositionx:-4.75,
            targetPositiony:1.86,
            targetPositionz:-2.06,
            targetRotationx:(-5.34/180)*Math.PI,
            targetRotationy:(351.1/180)*Math.PI,
            targetRotationz:0,
            stayTime:22
        },
        {//14
            targetPositionx:-0.12,
            targetPositiony:1.86,
            targetPositionz:-0.2,
            targetRotationx:(-4.64/180)*Math.PI,
            targetRotationy:(418.34/180)*Math.PI,
            targetRotationz:0,
            stayTime:18
        },
        {//15 播放结语
            targetPositionx:-0.12,
            targetPositiony:1.86,
            targetPositionz:-0.2,
            targetRotationx:(-4.64/180)*Math.PI,
            targetRotationy:(491.37/180)*Math.PI,
            targetRotationz:0,
            stayTime:3
        }
        
    ]
}