import {ArcRotateCamera, Mesh, Scene, SceneLoader,Quaternion, Vector3} from "@babylonjs/core";
import {AnimBlender} from "@/js/animBlender";
import {TpsInput} from "@/js/playerControl/tpsInput";


export class PlayerTPS extends Mesh {
    constructor(name: string, scene: Scene) {
        super(name, scene)
        this.scene = scene;
        this.camera = new ArcRotateCamera('player camera', 0, 1.5, 1, new Vector3(0,1.5,0))
        this.tpsInput = new TpsInput(this)
        this.camera.inputs.add(this.tpsInput)
        this.camera.attachControl()
        this.camera.parent = this
        this.camera.checkCollisions = false
        this.camera.panningDistanceLimit = 0.01
        this.camera.radius = 48
        this.camera.upperBetaLimit = 1.83
        this.camera.lowerRadiusLimit = 1.3
        this.camera.upperRadiusLimit = 5
        this.camera.wheelPrecision = 25
        this.initMesh().then()

    }
    switchVisibility(isVisible){
        this.tpsInput.joyOut.isVisible =isVisible ? 1 : 0
        this.tpsInput.joyInner.isVisible =isVisible ? 1 : 0

    }

    move(translate){
        if (translate.length() > 0) {
            let targetRotation = Quaternion.RotationQuaternionFromAxis(translate.clone(), Vector3.Up(), translate.cross(Vector3.Up())).multiply(Quaternion.RotationYawPitchRoll(Math.PI * 1.5, 0, 0))
            this.mesh.rotationQuaternion = Quaternion.Slerp(this.mesh.rotationQuaternion, targetRotation, 0.2)
            translate.scaleInPlace(0.06)
            translate.addInPlace(new Vector3(0, -0.1, 0))
            this.moveWithCollisions(translate)
            // console.log("translate",translate)
            this.animBlender.setTargetAnim('run')
            
        } else {
            this.animBlender &&  this.animBlender.setTargetAnim('idle')

        }
    }

    async initMesh() {
        SceneLoader.ImportMesh('', '', 'G_girl03.glb', this._scene,
            (meshes, particle, askeleton, animtions, nodes) => {
                this._scene.activeCamera = this.camera
                this.mesh = meshes[0]
                this.meshes = meshes;
                this.mesh.parent = this
                this.animBlender = new AnimBlender(animtions, this._scene, 'idle')
                this.ellipsoid = new Vector3(0.5, 1, 0.5)
                this.ellipsoidOffset = new Vector3(0, 0.85, 0)
            })
    }

}
