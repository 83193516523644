<template>
  <div id="app">
    <loading-program ref="loadingProgram" :tem_id="Number(tem_id)" v-show="loadingShow" @initNav="initNav" @changeLoadingStatus="changeLoadingStatus" class="loading-program-new"></loading-program>
    <bottom-nav ref="bottomNavs" :tem_id="Number(tem_id)"  @showHelpTab="showHelpTab" style="z-index:1200"></bottom-nav>

    <el-dialog
        title="操作提示"
        width="70%"
        :visible.sync="showHelp">
      <slot></slot>
      <el-image :src="helpSrc"></el-image>
    </el-dialog>
    
    <el-image-viewer
        v-if="showViewer"
        :on-close="closeViewer"
        :url-list="srcList"
    ></el-image-viewer>

    <img :src="wapImage"  v-show="wapImageShow" ref="tes" preview="1">

    <!-- <div id="right-menu" v-show="rightMemu">
      <ul>
        <li @click="switchFullScreen" class="menu-item"> 
          <span class="iconfont">&#xe649;</span>
        </li>
      </ul>
    </div> -->
    <canvas onselectstart="return false;" refs="tt" id="canvas" style="width: 100%;height: 100%;"></canvas>

    <!-- <div class="loading-program" v-show="loadingShow">
      <div>
        <div>
          <img src="sf02.png"/>
        </div>
        <div>
          <el-progress :text-inside="true" :stroke-width="22" :percentage="percentage" color="black"></el-progress>
        </div>
      </div>
    </div> -->
    <!-- <div class="bottom-left-img-show" v-show="bottomLeftShow" @click="showThumbnail">
      <div>
        <img src="piclist.png">
      </div>
      <div>
        场景
      </div>
    </div> -->


    <thumbnail v-show="isThumbnail" class="thumbnail"/>


    <div id="ll3d" v-if="isShowModel">
        <el-button @click="isShowModel = false" class="el-icon-close" style="position: absolute;right: 22%;top: 15%"
                 circle></el-button>
        <iframe width="100%" height="100%"
                :src="viewModelSrc"
                style="width: 100%;height: 100%;background: transparent;" frameborder="0" allowfullscreen  allowTransparency="true" 
                mozallowfullscreen="true" webkitallowfullscreen="true" onmousewheel="">
        </iframe>
    </div>
  </div>


</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import {Game} from "@/js/game";
import {getQueryVariable,getUserAgentInfo} from "@/js/tool";

import thumbnail from './components/thumbnail.vue'
import loadingProgram from './components/loadingProgram.vue'
import ViewModel from './ViewModel.vue';
import bottomNav from './components/bottomNav.vue';
import { getExhibitionTitle } from "@/js/api.js";
export default {
  name: 'Home',
  components: {
    ElImageViewer,
    thumbnail,
    ViewModel,
    "loading-program":loadingProgram,
    "bottom-nav":bottomNav
  },
  data() {
    return {
      percentage: 0,
      loadingShow: true,
      status: '',
      userData:[],
      musicEnable: true,
      showHelp:false,
      player:new Audio('autumn-talk.mp3'),
      playingAudio:null,
      rightMemu: false,
      isThumbnail:false,
      bottomLeftShow: false,
      imgUrl: '',
      srcList: [
        
      ],
      showViewer: false,
      wapImage: '',
      wapImageShow: false,
      isShowModel:false,
      tem_id:getQueryVariable("tem_id") || 0,
      mch_id:getQueryVariable("mch_id") || 0,
      viewModelSrc:'/viewModel',
      muted:false,
      helpSrc:''
    }
  },
  created(){
    //根据当前的mch_id与tem_id来查找当前的展馆名称
    
    this.getExhibitionTitle();
    
    let versions = getUserAgentInfo();
    if(versions.mobile){
      this.helpSrc = 'sj-kg.png';
    }else{
      this.helpSrc = 'dn-kg.png';
    }

  },
  methods: {
    //停止漫游
    getExhibitionTitle(){
      getExhibitionTitle({
        "mch_id":this.mch_id,
        "tem_id":this.tem_id
      }).then( res => {
        if(res.data.error == 0){
          document.title = res.data.data.title
        }else{
          document.title = '元宇宙展馆'
        }
      })
    },
    stopWander(){},
    initNav(){
      this.$refs.bottomNavs.setInitStatus('导航');
    },
    changeLoadingStatus(){
        this.loadingShow = false;
    },
    showHelpTab(){
      this.showHelp = true;
    },
    pauseTest(){
        let stayTime = localStorage.getItem('stayTime')
        let token = localStorage.getItem('token')

        let url = 'wapapi/addons/yb_guanwang/core/index.php/api/index/setStayTimeList'
        let data = {
            'stay_time':stayTime,
            'token': ''
        }
        if(token){
          data.token = token
        }
        fetch(url,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            keepalive:true
        })
    },
    // 测试生成模型
    showModel(modelUrl){
      this.viewModelSrc ='/viewModel?tem_id='+getQueryVariable("tem_id")+'&murl='+modelUrl;
      console.log(this.viewModelSrc)
      this.isShowModel = true;

    //   this.$refs.modelView.viewModelOpen()
    },
    isPhone() {
      var info = navigator.userAgent;
      var isPhone = /mobile/i.test(info);
      return isPhone;
    },
    stopMove () {
      const m = (e) => { e.preventDefault() };
      document.body.style.overflow = 'hidden';
      document.addEventListener("touchmove", m, false); // 禁止页面滑动
    },
// 开启页面滚动
    move () {
      const m = (e) => { e.preventDefault() };
      document.body.style.overflow = 'auto';
      document.removeEventListener("touchmove", m, true);
    },

    closeViewer(){
      this.showViewer = false
      this.move();
    },
    zoomImg(img){
      //判断当前是手机浏览还是PC浏览
      if(this.isPhone()){
        this.wapImage = img;
        setTimeout(() => {
          this.$refs.tes.click();
        },300)
      }else{
        setTimeout(() => {
          this.showViewer = true;
          this.stopMove();
          // this.srcList[0] = 'imag/' + img + '.jpg'
          this.srcList[0] = img;
        },300)
      }
    },
    playAudio(url){
      // return;
      if(this.playingAudio){
        this.playingAudio.pause();
        this.playingAudio = null;
      }
      if(url != ''){
      
        this.playingAudio = new Audio(url);
        try{
          this.playingAudio.play();
          if(this.muted){
             this.playingAudio.muted = this.muted
          }
        }catch(err){
          alert('error')
          }

      }
    },
    switchMusic(){
      this.muted = !this.muted;
      if(this.playingAudio){
        this.playingAudio.muted = this.muted;
      }
      window.game.sceneMeshParams.videos.map(vd=>{
          vd.muted = this.muted;
      });
      
    },
    zoomModel(){
      
        this.isShowModel = true;
        // this.viewModelSrc = url;
    },
    showThumbnail(){
      this.isThumbnail = !this.isThumbnail
    },
    switchFullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen()
      }
      
      document.documentElement.requestFullscreen()
    },
    toggleMusic() {
      var musicEnable = this.musicEnable
      if(musicEnable){
        this.musicEnable = false;
        this.player.pause();
      }else{
        this.musicEnable = true;
        this.player.play();
      }
      
    },
    getIsMuted(){
      return this.muted;
    }
  },  
  mounted() {
    if(!getQueryVariable("tem_id")){
      this.$message({
        showClose: true,
        message: '无法获取正确展馆编号',
        type: 'error'
      });
      return;
    }
    const glbSetting = this.exhibitionPostionXYZ
    window.switchMusic = this.switchMusic
    window.switchFullScreen = this.switchFullScreen
    window.showModel = this.showModel
    window.zoomImg = this.zoomImg
    window.playAudio = this.playAudio
    window.getIsMuted = this.getIsMuted;
    window.game = new Game(document.getElementById("canvas"),glbSetting)
    window.game.loadingManage.onDisplayObs.add(e => {
      console.log('loadStart')
    })
    window.game.loadingManage.onHideObs.add(e => {
      console.log('loadEnd')
      this.percentage = 100;
      this.status = 'success';
      setTimeout(() => {
        this.$refs.loadingProgram.readyEnter();
      }, 300)
    })
    window.game.loadingManage.onLoadingProgress.add(progress => {
      this.percentage = Number(progress)
    })
    window.addEventListener('beforeunload',e => this.pauseTest(e))
  },
  destroyed(){
    window.removeEventListener('beforeunload',e => this.pauseTest(e))
  },
  watch:{
    percentage(n){
      if(n > 95){
        this.rightMemu = true
        if(this.tem_id == 37 || this.tem_id == 44 || this.tem_id == 43){
        this.bottomLeftShow = true
        }

      }
    }
  }

}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
#ll3d {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.loading-program-new {
  z-index: 1999;
}
@media screen and (max-width: 768px) {
  .thumbnail{
    width: 100%;
    height: 70px;
    position: fixed;
    left: 0;
    bottom: 10%;
    background: rgba(0,0,0,0.3);
    overflow: scroll;
  }
}
@media screen and (min-width: 768px) {
  .thumbnail{
    width: 100%;
    height: 100px;
    position: fixed;
    left: 0;
    bottom: 10%;
    background: rgba(0,0,0,0.3);
  }
}
.bottom-left-img-show{
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;

}
.bottom-left-img-show > div:first-child{
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
}
.bottom-left-img-show > div:first-child > img{
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.bottom-left-img-show > div:nth-child(2){
  width: 50px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  color: rgb(112,112,112);

}
html, body, #app {
  width: 100%;
  height: 100%;
}
canvas {
  z-index: 1000;
}
.music {
  display: block;
  animation: music 2s linear infinite;
}
.loading-program {
  z-index: 1200;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: white url('/sf01.png') no-repeat center center; ;
  background-size:cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-program > div{
  width: 80%;
  max-width: 400px;

}
.loading-program > div > div:first-child{
  width: 100%;
}
.loading-program > div > div:first-child > img{
  width: 100%;
}
.loading-program > div > div:nth-child(2){
  width: 100%;
}



@keyframes music {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'iconfont';  /* Project id 3013934 */
  src: url('//at.alicdn.com/t/font_3013934_4rvk5e127bb.woff2?t=1652164516109') format('woff2'),
       url('//at.alicdn.com/t/font_3013934_4rvk5e127bb.woff?t=1652164516109') format('woff'),
       url('//at.alicdn.com/t/font_3013934_4rvk5e127bb.ttf?t=1652164516109') format('truetype');
}
.iconfont {
  font-family: "iconfont", serif !important;
  font-size: 18px;
  padding: 8px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #F8F8FF;
}


.menu-item {
  padding: 5px;
  font-size: 20px;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}
.menu-item.praise {
  color: #1989FA;
}
.menu-item.commentShow{
   color: #1989FA; 
}
li {
  list-style-type: none;
}
#right-menu {

  position: fixed;
  display: -webkit-flex; /* Safari */
  width: 30px;
  height: 200px;
  z-index: 1000;
  top: 32px;
  right: 24px;
}

.top-icon{
  position: fixed;
  top: 0px;
  right:  0px; 
}
.shadow-top{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  z-index: 9999;
}
</style>
