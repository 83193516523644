import {
    ActionManager,
    Color3, CubeTexture,
    Engine,
    ExecuteCodeAction,
    Mesh,
    MeshBuilder,
    PBRMaterial,
    Scene,
    SceneLoader, StandardMaterial, Texture,
    Vector3,
    Quaternion,
    VideoTexture,
    ShadowGenerator,
    Color4,
    IncrementValueAction,
    ParticleHelper,
    ParticleSystem,
    Action,
    DefaultRenderingPipeline,
    InstancedMesh,
    Effect,
    ShaderMaterial
} from "@babylonjs/core";

import {Player} from "@/js/playerControl/player";

import { PlayerTPS } from "@/js/playerControl/playerTPS";

import { LoadingManage } from "@/js/loadingManage";

import { SceneParams } from "@/js/sceneParams";

import {GalleryManage} from "@/js/gallery";

import {CameraControl} from "@/js/playerControl/cameraControl";

import '@babylonjs/inspector';

import "@babylonjs/loaders";

import { CustomJoyInput } from "@/js/customJoyInput";

import {getQueryVariable} from "@/js/tool";

import { ThinSprite } from "@babylonjs/core/Sprites/thinSprite";

import axios from "axios"

import { SceneMeshParams } from "@/js/sceneMeshParams";

//读取配置config.json文件
async function getGalleryConfig() {
    let response = await axios.get("./template/" + getQueryVariable("tem_id") + "/config.json")
    return response.data
}
export class Game {
    constructor(canvas: HTMLCanvasElement,glbSetting){
        //初始化场景
        this.engine = new Engine(canvas, true)
        this.canvas = canvas
        this.scene = new Scene(this.engine)

        //获取当前场景的tem_id(模板id)
        let tem_id = getQueryVariable("tem_id");
        this.tem_id = tem_id

        //处理场景参数  包含特殊处理  针对scene的处理都放到这个类里
        this.sceneParams = new SceneParams(this.scene,tem_id)
        this.sceneParams.initParams();

        //处理页面加载
        this.loadingManage = new LoadingManage()
        this.engine.loadingScreen = this.loadingManage

        //提高镜头亮度
        this.pipeLine = new DefaultRenderingPipeline('defaultPipe', true, this.scene, this.scene.cameras)
        this.pipeLine.samples = 8
        this.pipeLine.imageProcessing.toneMappingType = 1
        this.pipeLine.imageProcessing.vignetteEnabled = true
        this.pipeLine.chromaticAberrationEnabled = false
        this.pipeLine.chromaticAberration.aberrationAmount = 30
        this.pipeLine.chromaticAberration.radialIntensity = 0.65
        this.pipeLine.bloomEnabled = true

        //生成相机
        this.cameraControl = new CameraControl(tem_id,this.scene,glbSetting,this.canvas)
        this.cameraControl.initCamera();

        //debug模式开启
        window.addEventListener('keydown', e => {
            if (e.key === "i") {
                if (this.scene.debugLayer.isVisible()) {
                    this.scene.debugLayer.hide()
                } else {
                    this.scene.debugLayer.show({ handleResize: true, embedMode: true })
                }
            }
        })

        //导入场景
        this.CreateScene(glbSetting,tem_id).then( res => {
            // console.log(res)
            //调用接口替换全局内需要替换的图片
            this.engine.setHardwareScalingLevel(0.4);
            
            
            
            //模型处理
            this.sceneMeshParams = new SceneMeshParams(this.scene,tem_id,res.envTex,res.meshes)
            this.sceneMeshParams.initSceneMesh();
            this.galleryManage = new GalleryManage(this.scene)
            this.cameraControl.stayTimeListen();
            this.cameraControl.interactionListen();
            
            this.engine.hideLoadingUI();
            //默认直接开始漫游
            

        })
        
        this.engine.runRenderLoop(() => {
            this.scene.render()
        })

        window.addEventListener("resize", e => {
            this.engine.resize()
        })
    }
    startRoaming(){
        playAudio('音频/前言.mp3');
        setTimeout(() => {
            wanderStart(4)
        }, 12000);
        // this.cameraControl.playCameraAnimLoop(0);
        // this.cameraControl.startRoaming();
    }
    
    
    async CreateScene(glbSetting,tem_id):Promise<void> {
        let glbUrl = "./template/" + tem_id + "/";
        let qnUrl = "https://qn.ky3d.com/" + tem_id + "/";
        let glbName= tem_id + ".glb";
        let envName = tem_id + ".env";
        var url = qnUrl;
        if (process.env.NODE_ENV !== 'production') { 
            console.log('test')
           var url = glbUrl;
        }

        const envTex = CubeTexture.CreateFromPrefilteredData(url + envName,this.scene);//加载不同场馆的环境贴图

        const { meshes } = await SceneLoader.ImportMeshAsync('',url,glbName, this.scene, evt => {
            let loadedPercent = 0;
            if (evt.lengthComputable) {
                loadedPercent = (evt.loaded * 100 / evt.total).toFixed(0);
            }
            else {
                let dlCount = evt.loaded / (1024 * 1024);
                loadedPercent = Math.floor(dlCount * 100.0) / 100.0;
            }
            this.loadingManage.sendProgress(loadedPercent)
        })


        setTimeout( e => {
            this.pipeLine.imageProcessing.contrast = 1
            this.pipeLine.imageProcessing.exposure = 1
        },1000)

        
        //调整场景模型匹配人物模型大小
        if(glbSetting[getQueryVariable("tem_id")].glbScale != null){
            this.scene.rootNodes.forEach((rns) => {
                if(rns.id == '__root__'){
                    rns.scaling.x = glbSetting[getQueryVariable("tem_id")].glbScale;
                    rns.scaling.y = glbSetting[getQueryVariable("tem_id")].glbScale;
                    rns.scaling.z = -glbSetting[getQueryVariable("tem_id")].glbScale;
                }
            })
        }

        

        return Promise.resolve({
            'envTex': envTex,
            'meshes': meshes
        });
    }


    switchMusic() {
        if (this.music.isPlaying) {
            this.music.pause()
            return false
        } else {
            this.music.play()
            this.music.setVolume(1)
            return true
        }
    }
}
