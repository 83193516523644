import { 
    Scene,
    Engine,
    Color3,
    MeshBuilder,
    StandardMaterial,
    Texture,
    InstancedMesh,
    PBRMaterial,
    Mesh,
    ActionManager,
    ExecuteCodeAction,
    VideoTexture,
    ParticleSystem,
    Color4,
    Vector3,
    Effect,
    ShaderMaterial, 
    DefaultRenderingPipeline
} from "@babylonjs/core";

import * as GUI from "@babylonjs/gui";

import axios from "axios";

import {getQueryVariable} from "@/js/tool";
import { GUI3DManager, HolographicButton, PlanePanel } from "@babylonjs/gui";

//读取配置config.json文件
async function getGalleryConfig() {
    let response = await axios.get("./template/" + getQueryVariable("tem_id") + "/config.json")
    return response.data
}

export class SceneMeshParams {
    constructor(scene:Scene,tem_id:Number,envTex,meshes) {
        this.scene = scene
        this.tem_id = tem_id
        this.envTex = envTex
        this.meshes = meshes
        this.currentReplaceMesh = null;
        this.actionMesh = []
        this.videos = []
        this.advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('ui')
        this.videoPositions = [
            {
                targetPositionx:10.83,
                targetPositiony:1.86,
                targetPositionz:-8.42,
                targetRotationx:(-1/180)*Math.PI,
                targetRotationy:0,
                targetRotationz:0,
            },
            {
                targetPositionx:24.12,
                targetPositiony:1.86,
                targetPositionz:9.52,
                targetRotationx:0,
                targetRotationy:Math.PI/2,
                targetRotationz:0,
            },
            {
                targetPositionx:-12.21,
                targetPositiony:1.86,
                targetPositionz:6.72,
                targetRotationx:(-4.15/180)*Math.PI,
                targetRotationy:0,
                targetRotationz:0,
            }
        ]
        this.hotSports = []
    }
    async initSceneMesh(){
        this.initCollision();
        await this.initLightMap(this.scene);
        //特殊处理 如果有一样的参数会直接覆盖
        this.initGlass(this.meshes,this.envTex);
        let tem_id = Number(this.tem_id)
        
        let envTex = this.envTex
        let meshes = this.meshes
        
        if(envTex){
            // envTex.gammaSpace = false;
            this.scene.environmentTexture = envTex;
            // this.scene.environmentTexture.fogMode = 1;
            // let skyBox = this.scene.createDefaultSkybox(envTex,true,1000);
            // skyBox.applyFog =false
        }


        switch (tem_id) {
            case 37:
                let hideMesh = this.scene.getMeshByName('对象067-1');
                let hideMeshOne = this.scene.getMeshByName('对象066');
                hideMesh.visibility = false
                hideMeshOne.visibility = false

                envTex.rotationY = Math.PI/2;
                meshes.map(mesh=>{
                    if(mesh.name.indexOf('Plane116')> -1){
                        let particle = this.CreateParticle();
                        particle.emitter = new Vector3(4.74,2,-0.56);
                        particle.start();
                        let alpha = 0;
                        this.scene.registerBeforeRender(()=>{
                            mesh.position.y = 1.5 + Math.sin(alpha) * 0.25;
                            alpha += 0.01;
                        })
                    }
                })
                break;
            case 38:
                break;
            case 39:
                break;
            case 40:
                break;
            case 41:
                break;
            case 42:
                break;
            case 44:
                
                meshes.map(mesh=>{
                    if(mesh.name.indexOf("对象017")>-1){
                        if(!mesh.actionManager){
                            mesh.actionManager = new ActionManager(this.scene);
                        }
                        mesh.actionManager.registerAction(
                            new ExecuteCodeAction({ trigger: ActionManager.OnPickTrigger },
                                (e) => {
                                    showModel('lbp');
                                })
                                )
                            }
                        })
                        
                        this.envTex.rotationY = Math.PI;
                        this.initVideo('美尔奇品牌视频.mp4','video')
                        this.initVideo('美尔奇品牌视频.mp4','video.001')
                        this.initVideo('美尔奇品牌视频.mp4','video.002')
                        this.initCollision();
                        this.replaceMeshEvent();
                        this.initGlass(this.meshes,this.envTex);
                break;
            case 43:
                this.envTex.rotationY = Math.PI/2;
                this.scene.environmentIntensity = 3;
                break;
            case 45:
                this.scene.environmentIntensity = 2;
                this.scene.actionManager = new ActionManager(this.scene);
                meshes.map(mesh=>{
                    if(mesh.name.indexOf("polySurface31")>-1){
                        let alpha = 0;
                        this.scene.registerBeforeRender(()=>{
                            mesh.parent.position.y = 2 + Math.sin(alpha);
                            mesh.parent.rotation.y += alpha;
                            alpha += 0.01;
                        })
                    }
                    if(mesh.name.indexOf("polySurface32") > -1){
                        this.CreateShaderMat1(mesh);
                    }
                    if(mesh.name.indexOf("ZFG.006_primitive1") > -1){
                        this.CreateShaderMat2(mesh);
                    }
    
                })
                break;
            case 47:
                this.initVideo('video.mp4','live1');
                break;

            case 48:
                this.envTex.rotationY = -Math.PI/2;
                break;

            case 58:
              
                break;
        }


    }
    //初始化碰撞   传入一个scene  碰撞体不显示 但是检查碰撞
    initCollision() {
        this.scene.meshes.filter(mesh => mesh.name.indexOf('collision') !== -1 || mesh.name.indexOf('Collision') !== -1).forEach(mesh => {
            if (mesh instanceof Mesh) {
                mesh.checkCollisions = true
                mesh.visibility = 0;
            } else if (mesh instanceof InstancedMesh) {
                mesh.checkCollisions = true
            }
            mesh.isPickable = false;
        })
    }

    replaceMeshEvent(){
        const manager = new GUI3DManager(this.scene);
        this.scene.meshes.filter(mesh => mesh.name.indexOf('replace') !== -1).forEach(mesh=>{
            if(!mesh.actionManager){
                mesh.actionManager = new ActionManager(this.scene);
            }
            mesh.material.emissiveColor = Color3.White();
            console.log(mesh.material.emissiveColor);
            this.currentReplaceMesh = mesh;
            
            // const panel = new PlanePanel(this.scene);
            // manager.addControl(panel);
            // panel.linkToTransformNode(mesh);
            // panel.blockLayout = true;
            // const button = new HolographicButton(mesh.name+'btn');
            // panel.addControl(button);
            // panel.blockLayout = false;
            // button.text = mesh.name;
            // console.log(button)
            this.setInteractionBtn(mesh);
            // mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger,()=>{
            //     this.currentReplaceMesh = mesh;
            //     //调用前端页面展示
            //     //展示...
            //     if(!window.game.cameraControl.isRoaming)
            //     setMaterial('进入')
            // }))
        })
    }

    setInteractionBtn(mesh){
        let hotSport =  GUI.Button.CreateImageButton('btn','','/icon.png')
        this.hotSports.push(hotSport);
        hotSport.isVisible = 0
        hotSport.color = 'transparent'
        hotSport.width ='125px'
        hotSport.height ='125px'
        hotSport.image.width = '125px'
        hotSport.image.height = '125px'
        hotSport.image.horizontalAlignment  = 2
        this.advancedTexture.addControl(hotSport);
        hotSport.linkWithMesh(mesh);
        hotSport.onPointerClickObservable.add(e=>{
            // console.log('click');
            this.currentReplaceMesh = mesh;
            window.game.cameraControl.isRoaming = false;
            window.game.cameraControl.stopRoaming();
            wanderStart(2)
            setMaterial('进入')
        })

    }

    setHotSportVisibility(isVisible){
        console.log('set',isVisible)
        this.hotSports.forEach((btn)=>{
            btn.isVisible = isVisible ? 1 : 0;
        })
    }

    replaceMaterial(matUrl){
        if(this.currentReplaceMesh){
            let replaceMat = this.currentReplaceMesh.material;
            replaceMat.emissiveColor = Color3.White()
            replaceMat.albedoTexture = new Texture(matUrl,this.scene);
        }
    }

    initAlpha(scene:Scene) {
        scene.materials.forEach(mat => {
            if (mat instanceof PBRMaterial) {
                if (mat.alpha < 0.9) {
                    mat.transparencyMode = 2
                }
                if (mat.albedoTexture) {
                    mat.useAlphaFromAlbedoTexture = true
                    mat.albedoTexture.hasAlpha = true
                    mat.transparencyMode = 1
                }
            }
        })
    }

    initVideo(videoSrc,videoMeshName){
        let videoTexture = new VideoTexture('video'+videoMeshName,videoSrc,this.scene,false,true,VideoTexture.TRILINEAR_SAMPLINGMODE,{
            // poster:'play.png',
            loop:true,
            autoUpdateTexture:true,
            autoPlay:false,
            muted:true
        });
        videoTexture.video.width = 0;
        videoTexture.video.height = 0;

        this.videos.push(videoTexture.video);

        let videoMesh = this.scene.getMeshByName(videoMeshName);
        let videoMat : PBRMaterial = videoMesh.material.clone(videoMeshName);
        videoMesh.material = videoMat;
        
        videoMat.albedoTexture = videoTexture;
        videoMesh.actionManager = new ActionManager(this.scene);
        videoMesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickUpTrigger,()=>{
            if(!videoTexture.video.paused && !videoTexture.video.muted){
                videoTexture.video.pause();
            }else{
                videoTexture.video.play();
                videoTexture.video.muted =false;
                videoTexture.video.volume = 0.5;
                const vdIndex = this.videos.findIndex((value)=>{
                    return value == videoTexture.video;
                })
                const targetTransforms = this.videoPositions[vdIndex]
                // console.log(targetTransforms);
                window.game.cameraControl.playCameraAnim(
                    new Vector3(
                        targetTransforms.targetPositionx,
                        targetTransforms.targetPositiony,
                        targetTransforms.targetPositionz
                        ),
                        new Vector3(
                        targetTransforms.targetRotationx,
                        targetTransforms.targetRotationy,
                        targetTransforms.targetRotationz)
                    );
            }
        }))
    }
    //初始化玻璃材质
    initGlass(meshes,envTex){
        console.log('glass')
        let glass = new PBRMaterial('glass',this.scene);
        glass.reflectionTexture = envTex;    
        glass.indexOfRefraction = 0.52;
        glass.alpha = 0.5;
        glass.directIntensity = 0.0;
        glass.environmentIntensity = 0.25;
        glass.cameraExposure = 0.66;
        glass.cameraContrast = 1.66;
        glass.microSurface = 1;
        glass.reflectivityColor = new Color3(0.2, 0.2, 0.2);
        glass.albedoColor = new Color3(0.95, 0.95, 0.95);
        glass.subSurface.isRefractionEnabled = true;
        glass.subSurface.indexOfRefraction = 1.8;
        glass.subSurface.tintColor = new Color3(0.6, 0.94, 1);
        glass.metallic = 1;
        glass.roughness = 0.17;
  
        meshes.forEach(mesh=>{
            if(mesh.name.indexOf('Glass')>-1 || mesh.name.indexOf('glass')>-1) { 
                if(mesh.material instanceof PBRMaterial) {
                
                    if(mesh.name.indexOf('p')>=0){
                        let glassLightmap = glass.clone('glassLightmap');
                        glassLightmap.lightmapTexture = mesh.material.emissiveTexture;
                        glassLightmap.alpha = 0.75;
                        glassLightmap.environmentIntensity = 0.1;
                        mesh.material = glassLightmap;
                    }
                    else if(mesh.name.indexOf('green')>=0){
                        let glassGreen = glass.clone('glassGreen');
                        glassGreen.emissiveColor = new Color3(0.02, 0.35, 0.18);
                        glassGreen.alpha = 0.95;
                        mesh.material = glassGreen;
                    }else{
                        mesh.material = glass;
                    }
                }
            }
        })
    }

    //初始化灯光
    async initLightMap(scene) {
        let denyInitLightMesh = [];
        let config = await getGalleryConfig()
        config.send_locate.forEach(e => {
            if(e.file_name){
                denyInitLightMesh.push(e.file_name)
            }else{
                denyInitLightMesh.push(e.title)
            }

        })
        if(config.id==47){
            this.scene.meshes.filter(mesh => mesh.name.indexOf('lightmap') !== -1).forEach(mesh => {
                let mat = mesh.material
                if (mat instanceof PBRMaterial) {
                    mat.lightmapTexture = mat.emissiveTexture
                    mat.emissiveTexture = null
                    mat.emissiveColor = Color3.Black()
                }
            })
            this.scene.meshes.filter(mesh => mesh.name.indexOf('hide') !== -1).forEach(mesh => {
                if(mesh instanceof Mesh){
                    mesh.visibility=0;
                }
            })
        }else{
            // console.log(scene.meshes)
            scene.meshes.forEach(mesh => {
                if(mesh.name === 'BackgroundPlane') {
                    mesh.visibility = 0
                }
                if (mesh.material instanceof PBRMaterial) {
                    if (mesh.material.emissiveTexture) {
                        if(!denyInitLightMesh.includes(mesh.name)){
                            //光照贴图实现2                     
                            //根据config里配置实现光照贴图在配置表内不实现方法
                            if(this.tem_id==44){

                                mesh.material.ambientTexture = mesh.material.emissiveTexture
                                mesh.material.ambientTexture.isRGBD = true
                            }else{
                            mesh.material.lightmapTexture = mesh.material.emissiveTexture
                            mesh.material.useLightmapAsShadowmap = true}
                        }
                    }
                    if(mesh.name.indexOf("ZFG")>-1 || mesh.name.indexOf("group110")>-1 || mesh.name.indexOf("group98")>-1){
                        mesh.material.emissiveColor = Color3.White();
                        if(mesh.name.indexOf("ZFG.005_primitive0") > -1)
                        {
                            console.log('intensity')
                            mesh.material.emissiveIntensity = 10;
                        }
                    }else{
                        mesh.material.emissiveColor = Color3.Black()
                    }
                    //环境色
                    mesh.material.ambientColor = Color3.White()
                }
            })
        }
    }

    //增设特效
    CreateParticle(){
        let particleSystem = new ParticleSystem("particle",2000,this.scene);
        particleSystem.particleTexture = new Texture("./flare.png",this.scene);
        // Colors of all particles
        particleSystem.color1 = new Color4(0.7, 0.8, 1.0, 1.0);
        particleSystem.color2 = new Color4(0.2, 0.5, 1.0, 1.0);
        particleSystem.colorDead = new Color4(0, 0, 0.2, 0.0);
        // Size of each particle (random between...
        particleSystem.minSize = 0.1;
        particleSystem.maxSize = 0.5;
        // Life time of each particle (random between...
        particleSystem.minLifeTime = 0.3;
        particleSystem.maxLifeTime = 0.55;
        // Emission rate
        particleSystem.emitRate = 50;
        /******* Emission Space ********/
        particleSystem.createSphereEmitter(2);
        // Speed
        particleSystem.minEmitPower = 1;
        particleSystem.maxEmitPower = 3;
        particleSystem.updateSpeed = 0.005;
            
        particleSystem.updateFunction = function(particles) {
            for (var index = 0; index < particles.length; index++) {
                  var particle = particles[index];
                  particle.age += this._scaledUpdateSpeed;
                                  if (particle.age >= particle.lifeTime) { // Recycle
                       particles.splice(index, 1);
                       this._stockParticles.push(particle);
                       index--;
                       continue;
                  }
                  else {
                       particle.colorStep.scaleToRef(this._scaledUpdateSpeed, this._scaledColorStep);
                       particle.color.addInPlace(this._scaledColorStep);
                       particle.color = new Color4(Math.random(), Math.random(), Math.random(), 1)
                                           if (particle.color.a < 0)
                                    particle.color.a = 0;
                                           particle.angle += particle.angularSpeed * this._scaledUpdateSpeed;
                                           particle.direction.scaleToRef(this._scaledUpdateSpeed, this._scaledDirection);
                       particle.position.addInPlace(this._scaledDirection);
                                           this.gravity.scaleToRef(this._scaledUpdateSpeed, this._scaledGravity);
                       particle.direction.addInPlace(this._scaledGravity);
                  }
            } 
        }
        return particleSystem;
    }

    CreateShaderMat1(mesh){
        Effect.ShadersStore["FogVertexShader"]= `   
		precision highp float;

    	// Attributes
    	attribute vec3 position;
    	attribute vec2 uv;

    	// Uniforms
    	uniform mat4 worldViewProjection;

    	// Varying
    	varying vec2 vUV;

    	void main(void) {
    	    gl_Position = worldViewProjection * vec4(position, 1.0);

    	    vUV = uv;
    	}`;
        Effect.ShadersStore["FogFragmentShader"]=`
        precision highp float;
 
        uniform vec3      iResolution;           // 视口分辨率（像素）
        uniform float     iTime;                 // 着色器播放时间（秒）
        varying vec2 vUV;
        #define USE_LIGHT 1
 
        mat3 m = mat3( 0.00,  0.80,  0.60,
                      -0.80,  0.36, -0.48,
                      -0.60, -0.48,  0.64);
        
        float hash(float n)
        {
            return fract(sin(n) * 43758.5453);
        }
        
        ///
        /// Noise function
        ///
        float noise(in vec3 x)
        {
            vec3 p = floor(x);
            vec3 f = fract(x);
            
            f = f * f * (3.0 - 2.0 * f);
            
            float n = p.x + p.y * 57.0 + 113.0 * p.z;
            
            float res = mix(mix(mix(hash(n +   0.0), hash(n +   1.0), f.x),
                                mix(hash(n +  57.0), hash(n +  58.0), f.x), f.y),
                            mix(mix(hash(n + 113.0), hash(n + 114.0), f.x),
                                mix(hash(n + 170.0), hash(n + 171.0), f.x), f.y), f.z);
            return res;
        }
        
        ///
        /// Fractal Brownian motion.
        ///
        /// Refer to:
        /// EN: https://thebookofshaders.com/13/
        /// JP: https://thebookofshaders.com/13/?lan=jp
        ///
        float fbm(vec3 p)
        {
            float f;
            f  = 0.5000 * noise(p); p = m * p * 2.02;
            f += 0.2500 * noise(p); p = m * p * 2.03;
            f += 0.1250 * noise(p);
            return f;
        }
        
        //////////////////////////////////////////////////
        
        ///
        /// Sphere distance function.
        ///
        /// But this function return inverse value.
        /// Normal dist function is like below.
        /// 
        /// return length(pos) - 0.1;
        ///
        /// Because this function is used for density.
        ///
        float scene(in vec3 pos)
        {
            return 0.1 - length(pos) * 0.05 + fbm(pos * 0.3);
        }
        
        ///
        /// Get normal of the cloud.
        ///
        vec3 getNormal(in vec3 p)
        {
            const float e = 0.01;
            return normalize(vec3(scene(vec3(p.x + e, p.y, p.z)) - scene(vec3(p.x - e, p.y, p.z)),
                                  scene(vec3(p.x, p.y + e, p.z)) - scene(vec3(p.x, p.y - e, p.z)),
                                  scene(vec3(p.x, p.y, p.z + e)) - scene(vec3(p.x, p.y, p.z - e))));
        }
        
        ///
        /// Create a camera pose control matrix.
        ///
        mat3 camera(vec3 ro, vec3 ta)
        {
            vec3 cw = normalize(ta - ro);
            vec3 cp = vec3(0.0, 1.0, 0.0);
            vec3 cu = cross(cw, cp);
            vec3 cv = cross(cu, cw);
            return mat3(cu, cv, cw);
        }
 
        void main(void) {
            vec2 uv = (vUV.xy * 2.0 - iResolution.xy) / min(iResolution.x, iResolution.y);
     
            vec2 mo = vec2(iTime * 0.1, cos(iTime * 0.25) * 3.0);

            // Camera
            float camDist = 25.0;

            // target
            vec3 ta = vec3(0.0, 1.0, 0.0);

            // Ray origin
            //vec3 ori = vec3(sin(iTime) * camDist, 0, cos(iTime) * camDist);
            vec3 ro = camDist * normalize(vec3(cos(2.75 - 3.0 * mo.x), 0.7 - 1.0 * (mo.y - 1.0), sin(2.75 - 3.0 * mo.x)));

            float targetDepth = 1.3;

            // Camera pose.
            mat3 c = camera(ro, ta);
            vec3 dir = c * normalize(vec3(uv, targetDepth));

            // For raymarching const values.
            const int sampleCount = 64;
            const int sampleLightCount = 6;
            const float eps = 0.01;

            // Raymarching step settings.
            float zMax = 40.0;
            float zstep = zMax / float(sampleCount);

            float zMaxl = 20.0;
            float zstepl = zMaxl / float(sampleLightCount);

            // Easy access to the ray origin
            vec3 p = ro;

            // Transmittance
            float T = 1.0;

            // Substantially transparency parameter.
            float absorption = 100.0;

            // Light Direction
            vec3 sun_direction = normalize(vec3(1.0, 0.0, 0.0));

            // Result of culcration
            vec4 color = vec4(0.0);

            for (int i = 0; i < sampleCount; i++)
            {
                // Using distance function for density.
                // So the function not normal value.
                // Please check it out on the function comment.
                float density = scene(p);

                // The density over 0.0 then start cloud ray marching.
                // Why? because the function will return negative value normally.
                // But if ray is into the cloud, the function will return positive value.
                if (density > 0.0)
                {
                    // Let's start cloud ray marching!

                    // why density sub by sampleCount?
                    // This mean integral for each sampling points.
                    float tmp = density / float(sampleCount);

                    T *= 1.0 - (tmp * absorption);

                    // Return if transmittance under 0.01. 
                    // Because the ray is almost absorbed.
                    if (T <= 0.01)
                    {
                        break;
                    }

                    #if USE_LIGHT == 1
                    // Light scattering

                    // Transmittance for Light
                    float Tl = 1.0;

                    // Start light scattering with raymarching.

                    // Raymarching position for the light.
                    vec3 lp = p;

                    // Iteration of sampling light.
                    for (int j = 0; j < sampleLightCount; j++)
                    {
                        float densityLight = scene(lp);

                        // If densityLight is over 0.0, the ray is stil in the cloud.
                        if (densityLight > 0.0)
                        {
                            float tmpl = densityLight / float(sampleCount);
                            Tl *= 1.0 - (tmpl * absorption);
                        }

                        if (Tl <= 0.01)
                        {
                            break;
                        }

                        // Step to next position.
                        lp += sun_direction * zstepl;
                    }
                    #endif

                    // Add ambient + light scattering color
                    float opaity = 50.0;
                    float k = opaity * tmp * T;
                    vec4 cloudColor = vec4(1.0);
                    vec4 col1 = cloudColor * k;

                    #if USE_LIGHT == 1
                    float opacityl = 30.0;
                    float kl = opacityl * tmp * T * Tl;
                    vec4 lightColor = vec4(1.0, 0.7, 0.9, 1.0);
                    vec4 col2 = lightColor * kl;
                    #else
                    vec4 col2 = vec4(0.0);
                    #endif

                    color += col1 + col2;
                }

                p += dir * zstep;
            }

            vec3 bg = mix(vec3(0.3, 0.1, 0.8), vec3(0.7, 0.7, 1.0), 1.0 - (uv.y + 1.0) * 0.5);
            color.rgb += bg;
            gl_FragColor = color;
         }`;
        let shaderMaterial = new ShaderMaterial('shaderFog',this.scene,{
            vertex:"Fog",
            fragment: "Fog",
	    },
        {
			attributes: ["position", "normal", "uv"],
			uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"]
        })
        let iTime=0;
        let max = mesh.getBoundingInfo().boundingBox.maximum
        let min = mesh.getBoundingInfo().boundingBox.minimum
        shaderMaterial.setVector3('iResolution',new Vector3(1,1,1));
        this.scene.registerBeforeRender(()=>{
            shaderMaterial.setFloat('iTime',iTime);
            iTime +=0.01;
        })
        shaderMaterial.alpha = 0;
        mesh.material = shaderMaterial;
    }
    
    CreateShaderMat2(mesh){
        Effect.ShadersStore["LineVertexShader"]= `   
		precision highp float;
    	// Attributes
    	attribute vec3 position;
    	attribute vec2 uv;

    	// Uniforms
    	uniform mat4 worldViewProjection;

    	// Varying
    	varying vec2 vUV;

    	void main(void) {
    	    gl_Position = worldViewProjection * vec4(position, 1.0);

    	    vUV = uv;
    	}`;
        Effect.ShadersStore["LineFragmentShader"]=`
	   precision highp float;

       	uniform vec3      iResolution;           // 视口分辨率（像素）
		uniform float     iTime;                 // 着色器播放时间（秒）
    	varying vec2 vUV;
        float map(vec3 p) {
            vec3 n = vec3(0, 1, 0);
            float k1 = 1.9;
            float k2 = (sin(p.x * k1) + sin(p.z * k1)) * 0.8;
            float k3 = (sin(p.y * k1) + sin(p.z * k1)) * 0.8;
            float w1 = 4.0 - dot(abs(p), normalize(n)) + k2;
            float w2 = 4.0 - dot(abs(p), normalize(n.yzx)) + k3;
            float s1 = length(mod(p.xy + vec2(sin((p.z + p.x) * 2.0) * 0.3, cos((p.z + p.x) * 1.0) * 0.5), 2.0) - 1.0) - 0.2;
            float s2 = length(mod(0.5+p.yz + vec2(sin((p.z + p.x) * 2.0) * 0.3, cos((p.z + p.x) * 1.0) * 0.3), 2.0) - 1.0) - 0.2;
            return min(w1, min(w2, min(s1, s2)));
        }
        
        vec2 rot(vec2 p, float a) {
            return vec2(
                p.x * cos(a) - p.y * sin(a),
                p.x * sin(a) + p.y * cos(a));
        }
        
                void main(void) {
                     float time = iTime;
            vec2 uv = ( vUV.xy / iResolution.xy ) * 2.0 - 1.0;
            uv.x *= iResolution.x /  iResolution.y;
            vec3 dir = normalize(vec3(uv, 1.0));
            dir.xz = rot(dir.xz, time * 0.23);dir = dir.yzx;
            dir.xz = rot(dir.xz, time * 0.2);dir = dir.yzx;
            vec3 pos = vec3(0, 0, time);
            vec3 col = vec3(0.0);
            float t = 0.0;
            float tt = 0.0;
            for(int i = 0 ; i < 100; i++) {
                tt = map(pos + dir * t);
                if(tt < 0.001) break;
                t += tt * 0.45;
            }
            vec3 ip = pos + dir * t;
            col = vec3(t * 0.1);
            col = sqrt(col);
            gl_FragColor = vec4(0.05*t+abs(dir) * col + max(0.0, map(ip - 0.1)-tt), 1.0);
    	}`;
        let shaderMaterial = new ShaderMaterial('shaderLine',this.scene,{
            vertex:"Line",
            fragment: "Line",
	    },
        {
			attributes: ["position", "normal", "uv"],
			uniforms: ["world", "worldView", "worldViewProjection", "view", "projection"]
        })
        let iTime=0;
        shaderMaterial.setVector3('iResolution',new Vector3(1,1,1));
        this.scene.registerBeforeRender(()=>{
            shaderMaterial.setFloat('iTime',iTime);
            iTime +=0.01;
        })
        shaderMaterial.alpha = 0;
        mesh.material = shaderMaterial;
    }
}   
